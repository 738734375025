import React, {Component} from 'react';
import Header from './Navigattion/Header';
import Footer from './Navigattion/Footer';
import { Seo } from '../helpers/Seo';


class Home extends Component {
    render() {
        return (
            <div>
              <Seo
                  title="Prêts personnels approuvés de 100 $ au Canada | Aucune vérification de crédit | Bon financement"
                  description="Découvrez des prêts personnels rapides et faciles avec une approbation de 100 $ chez Good Good Finance. Obtenez le soutien financier dont vous avez besoin aujourd'hui grâce à notre application simple."
                  type="webapp"
                  name="POLITIQUE DE CONFIDENTIALITÉ"
              />
              <Header />
              <br/><br/>
                <main id="main">
                   <div>
                       <section className="about">
                  <div className="container" data-aos="fade-up">
                    <div className="section-title">
                      <h2>POLITIQUE DE CONFIDENTIALITÉ</h2>
                      <h5>goodgoodfinance.com</h5>
                      <h5>Good Good Finance</h5>
                      <h5>Type de site : e-commerce</h5>
                    </div>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3>Le but de cette politique de confidentialité</h3>
                        <p>  Le but de cette politique de confidentialité est d'informer les utilisateurs de notre site des données personnelles que nous recueillerons ainsi que les informations suivantes, le cas échéant : </p>
                        a. Les données personnelles que nous recueillerons<br/>
                        b. L’utilisation des données recueillies<br/>
                        c. Qui a accès aux données recueillies<br/>
                        d. Les droits des utilisateurs du site<br/>
                        e. La politique de cookies du site<br/>
                        <p>Cette politique de confidentialité fonctionne parallèlement aux conditions générales d’utilisation de notre site.</p>
                      </div>
                    </div>
                    <br/>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3>Lois applicables</h3>
                        <p>  Cette politique est conforme aux lois énoncées dans la loi sur la protection des renseignements personnels et les documents électroniques (LPRPDE) </p>
                        <p>Pour les résidents des pays de l’UE, le Règlement général sur la protection des données (RGDP) régit toutes les politiques de protection des données, quel que soit l’endroit où se trouve le site. La présente politique de confidentialité vise à se conformer au RGDP. S’il y a des incohérences entre la présente politique et le RGDP, le RGDP s’appliquera</p>
                        <p>Pour les résidents de l’État de Californie, cette politique de confidentialité vise à se conformer à la California Consumer Privacy Act (CCPA). S’il y a des incohérences entre ce document et la CCPA, la législation de l’État s’appliquera. Si nous constatons des incohérences, nous modifierons notre politique pour nous conformer à la loi pertinente</p>
                      </div>
                    </div>
                    <br/>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3>Consentement</h3>
                        <p>  Les utilisateurs conviennent qu’en utilisant notre site, ils consentent à : </p>
                        a. Les conditions énoncées dans la présente politique de confidentialité et<br/>
                        b. La collecte, l’utilisation et la conservation des données énumérées dans la présente politique.<br/>
                      </div>
                    </div>
                    <br/>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3>Quelles sont les Données personnelles que nous collectons ?</h3>
                        <h3>Données collectées automatiquement</h3>
                        <p>  Lorsque vous visitez et utilisez notre site, nous pouvons automatiquement recueillir et conserver les renseignements suivants : </p>
                        a. Liens un utilisateur clique tout en utilisant le site. <br/>
                        b. Contenu que l’utilisateur consulte sur votre site. <br/>
                      </div>
                    </div>
                    <br/>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3>Données recueillies de façon non automatique</h3>
                        <p> Nous pouvons également collecter les données suivantes lorsque vous effectuez certaines fonctions sur notre site : </p>
                        a. Prénom et nom<br/>
                        b. Âge<br/>
                        c. Sexe<br/>
                        d. Email<br/>
                        e. Numéro de téléphone<br/>
                        a. Domicile<br/>
                        b. Profession et numéro de téléphone de l’employeur<br/>
                        c. Date d’embauche et date de la prochaine paie<br/>
                        d. Informations sur les revenus et dépenses<br/>
                        <p>Ces données peuvent être recueillies au moyen des méthodes suivantes :</p>
                        <p>- Formulaires (formulaire de demande de prêt sur le site) remplis par l’utilisateur pour obtenir un produit de Good Good Finance ou des informations sur les produits.</p>
                        <p>Veuillez noter que nous ne collectons que les données qui nous aident à atteindre l’objectif énoncé dans cette politique de confidentialité. Nous ne recueillerons pas de données supplémentaires sans vous en informer au préalable</p>
                      </div>
                    </div>
                    <br/>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3>Avec qui nous partageons les données personnelles?</h3>
                        <h3>Employés</h3>
                        <p> Nous pouvons divulguer à tout membre de notre organisation les données utilisateur dont il a raisonnablement besoin pour réaliser les objectifs énoncés dans la présente politique. </p>
                        <h3>Tierces parties</h3>
                        <p> Nous pouvons partager certaines données utilisateur avec des tiers aux fins suivantes : </p>
                        a. Informations sur les liens cliqués lors de l'utilisation du site Good Good Finance<br/>
                        <p>Les tiers ne seront pas en mesure d’accéder aux données des utilisateurs au-delà de ce qui est raisonnablement nécessaire pour atteindre l’objectif donné.</p>
                      </div>
                    </div>
                    <br/>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3>Autres divulgations</h3>
                        <p> Nous nous engageons à ne pas vendre ou partager vos données avec d'autres tiers, sauf dans les cas suivants :</p>
                        a. Si la loi l'exige<br/>
                        b. Si elle est requise pour toute procédure judiciaire<br/>
                        c. Pour prouver ou protéger nos droits légaux<br/>
                        d. À des acheteurs ou des acheteurs potentiels de cette société dans le cas où nous cherchons à la vendre la société<br/>
                        <p>Si vous suivez des hyperliens de notre site vers un autre site, veuillez noter que nous ne sommes pas responsables et n’avons pas de contrôle sur leurs politiques et pratiques de confidentialité.</p>
                      </div>
                    </div>
                    <br/>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3>Combien de temps nous stockons les données personnelles</h3>
                        <p> Nous ne conservons pas les données des utilisateurs au-delà de ce qui est nécessaire pour atteindre les fins pour lesquelles elles sont recueillies.</p>
                      </div>
                    </div>
                    <br/>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3>Comment nous protégeons vos données personnelles</h3>
                        <p> Toutes les données stockées dans notre système sont bien sécurisées et ne sont accessibles qu’à nos employés. Nos employés sont liés par des accords de confidentialité stricts et une violation de cet accord entraînerait le licenciement de l’employé.</p>
                        <p>Alors que nous prenons toutes les précautions raisonnables pour nous assurer que nos données d’utilisateur sont sécurisées et que les utilisateurs sont protégés, il reste toujours du risque de préjudice.</p>
                        <p>L’Internet en sa totalité peut être, parfois, peu sûr et donc nous sommes incapables de garantir la sécurité des données des utilisateurs au-delà de ce qui est raisonnablement pratique.</p>
                      </div>
                    </div>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3>Mineurs</h3>
                        <p> Pour les résidents de l’UE, le RGPD précise que les personnes de moins de 15 ans sont considérées comme des mineurs aux fins de la collecte de données. Les mineurs doivent avoir le consentement d’un représentant légal pour que leurs données soient recueillies, traitées et utilisées.</p>
                      </div>
                    </div>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3>Vos droits en tant qu’utilisateur</h3>
                        <p> En tant qu’utilisateur, vous avez le droit d’accéder à toutes vos données personnelles que nous avons collectées. En outre, vous avez le droit de mettre à jour ou de corriger toute donnée personnelle en notre possession à condition qu’elle soit acceptable en vertu de la loi.</p>
                        <p>Vous pouvez choisir de supprimer ou de modifier votre consentement à la collecte et à l’utilisation des données en tout temps, pourvu qu’il soit légalement acceptable de le faire et que vous nous en ayez informé dans un délai raisonnable.</p>
                      </div>
                    </div>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3>Comment modifier, supprimer ou contester les données recueillies</h3>
                        <p> Si vous souhaitez que vos renseignements soient supprimés ou modifiés d’une façon ou d’une autre, veuillez communiquer avec notre agent de protection de la vie privée ici :</p>
                        <p>Nicolas Oulerich</p>
                        <p>info@goodgoodfiance.com</p>
                      </div>
                    </div>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3>Politique sur les cookies</h3>
                        <p> Un cookie est un petit fichier, stocké sur le disque dur d’un utilisateur par le site Web. Son but est de recueillir des données relatives aux habitudes de navigation de l’utilisateur.</p>
                        <p>Nous utilisons les types de cookies suivants sur notre site :</p>
                        <h5>a. Cookies fonctionnels</h5>
                        <p>Nous les utilisons pour mémoriser toutes les sélections que vous faites sur notre site afin qu’elles soient sauvegardées pour vos prochaines visites.</p>
                        <h5>b. Cookies analytiques</h5>
                        <p>Cela nous permet d’améliorer la conception et la fonctionnalité de notre site en recueillant des données sur le contenu auquel vous accédez et sur lequel vous vous accrochez en utilisant notre site.</p>
                        <h5>c. Cookies de ciblage</h5>
                        <p>Ces cookies collectent des données sur la façon dont vous utilisez le site et vos préférences. Cela nous permet de rendre les informations que vous voyez sur notre site plus promotionnelles et ciblées pour vous.</p>
                        <p>Vous pouvez choisir d’être averti chaque fois qu’un cookie est transmis. Vous pouvez également choisir de désactiver les cookies entièrement dans votre navigateur Internet, mais cela peut diminuer la qualité de votre expérience d’utilisation.</p>
                      </div>
                    </div>
                    <div className="row content">
                      <div className="col-lg-12">
                        <h3>Cookies tiers</h3>
                        <p> Nous pouvons utiliser des cookies tiers sur notre site pour atteindre nos objectifs de suivi et de publicité dont voici les noms :</p>
                        <h5>Google</h5>
                        <p>Le site internet de Good Good Finance intègre des fonctionnalités de publicité Google incluant des cookies publicitaires Google et des cookies Google Analytics. Cette fonctionnalité peut être désactivée via les paramètres de vos annonces ou en suivant le lien qui suit <a href="https://tools.google.com/dlpage/gaoptout/">ici.</a></p>
                        <h5>Modifications</h5>
                        <p>Cette politique de confidentialité peut être modifiée à l’occasion afin de maintenir la conformité avec la loi et de tenir compte de tout changement à notre processus de collecte de données. Nous recommandons à nos utilisateurs de vérifier notre politique de temps à autre pour s’assurer qu’ils soient informés de toute mise à jour. Au besoin, nous pouvons informer les utilisateurs par courriel des changements apportés à cette politique.</p>
                        <h5>Contact</h5>
                        <p>Si vous avez des questions à nous poser, n’hésitez pas à communiquer avec nous en utilisant ce qui suit :</p>
                        <p>info@goodgoodfinance.com</p>
                        <p>Date d’entrée en vigueur : le 30 juillet 2020</p>
                      </div>
                    </div>

                    

                    
                  </div>
                </section>
                
      

         
    </div>
                </main>
              <Footer />
            </div>
          );
    }
}
export default  Home;

