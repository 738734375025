import axios from 'axios';
import React, {Component} from 'react';
import Header from './Navigattion/Header';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { Stepper } from 'react-form-stepper';
import Swal from 'sweetalert2';
import { Translation } from 'react-i18next';
import i18next from 'i18next';
import Autocomplete from "react-google-autocomplete";
import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import '../../src/assets/MyStyle.css';
import Spinner from 'react-bootstrap/Spinner';
import { Seo } from '../helpers/Seo';


class NewLead extends React.Component {
   //Init
    constructor(props) {
        super(props);
        this.state = {
            client_id: null,
            step: 1,
            email: '',
            name: '',
            prenom: '',
            password: '',
            passwordConfirm: '',
            telephone: '',
            montant: '',
            frequence_remboursement: '',
            date_naissance: '',
            adresse: '',
            appartement: '',
            ville: '',
            code_postal: '',
            province: '',
            origine_revenu: '',
            autre_origine_revenu: '',
            nom_employeur: '',
            telephone_employeur: '',
            occupation: '',
            situation_emploi: '',
            date_embauche: '',
            date_prochaine_paie: '',
            nom_reference: '',
            telephone_reference: '',
            faillite: '',
            revenu_mensuel_brut: '',
            loyer_mensuel: '',
            montant_electricite_mensuel: '',
            montant_loc_auto: '',
            montant_achat_meuble: '',
            montant_autre_oblig: '',
            compte_conjoint: '',
            nom_compte_conjoint: '',
            isActive:false,
            isAccount:false,
            isOther:false,
            dob:'',
            sourcePret: 'GGF',
            source: 'GGF',
            toSubmit: false,
            loading: false,
            utm_source: '',
            utm_campaign: '',
            utm_medium: '',
            endorser: 0,
            error_list: [],
            errors: {}};
      }
      toggleLoader = () => {
        if(!this.state.loading){
          this.setState({loading: true})
        }else{
          this.setState({loading: false})
        }
     }
    getDate = (dob) =>{
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const today = new Date();
        const firstDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
        const secondDate = new Date(dob.$y, dob.$M, dob.$D);
        const diffDays = Math.round((firstDate - secondDate) / oneDay);
        return diffDays
    }
    nextStep = (e) =>{
        const {name, prenom, email, password, client_id, passwordConfirm, telephone, sourcePret, source, endorser, step, frequence_remboursement,montant,date_naissance, adresse, ville, code_postal,origine_revenu,nom_employeur, telephone_employeur, occupation, situation_emploi, date_embauche, date_prochaine_paie, nom_reference, telephone_reference, autre_origine_revenu, revenu_mensuel_brut, loyer_mensuel, compte_conjoint, nom_compte_conjoint} = this.state;
        let isValid = true;
       const errors = {}
       //Validation
       console.log('start validation');
        if(step == 1){
            if(name.trim().length < 2){
                errors.usernameLength = <Translation>{(t) => <span>{t("valid_required_1")}</span>}</Translation>;
                isValid = false
            }
            if(prenom.trim().length < 2){
                errors.userprenomLength = <Translation>{(t) => <span>{t("valid_required_1")}</span>}</Translation>;
                isValid = false
            }
            if(password.trim().length < 2){
                errors.userpasswordLength = <Translation>{(t) => <span>{t("valid_required_1")}</span>}</Translation>;
                isValid = false
            }
            if(passwordConfirm.trim().length < 2){
                errors.userpasswordConfirmLength = <Translation>{(t) => <span>{t("valid_required_1")}</span>}</Translation>;
                isValid = false
            }else{
                if(password != passwordConfirm){
                    errors.userpasswordConfirmLength = <Translation>{(t) => <span>{t("valid_required_8")}</span>}</Translation>;
                    isValid = false
                }
            }
            if(telephone.trim().length < 10){
                errors.userphone =  <Translation>{(t) => <span>{t("valid_required_2")}</span>}</Translation>;
                isValid = false
            }
        }
        if(step == 2){
            if(!montant){
                errors.userMontant = <Translation>{(t) => <span>{t("valid_required_3")}</span>}</Translation>;
                isValid = false
            }
            if(!frequence_remboursement){
                errors.userFrequence = <Translation>{(t) => <span>{t("valid_required_3")}</span>}</Translation>;
                isValid = false
            }
            if(!date_naissance){
                errors.userDateNaissance = <Translation>{(t) => <span>{t("valid_required_3")}</span>}</Translation>;
                isValid = false
            }else{
                var dob = date_naissance;
                var age = this.getDate(dob)
                if(age < 18){
                    errors.userDateNaissance = <Translation>{(t) => <span>{t("valid_required_4")}</span>}</Translation>;
                    isValid = false
                }
            }
            if(!adresse){
                errors.userAdresse = <Translation>{(t) => <span>{t("valid_required_3")}</span>}</Translation>;
                isValid = false
            }
            if(!ville){
                errors.userVille =<Translation>{(t) => <span>{t("valid_required_3")}</span>}</Translation>;
                isValid = false
            }
            if(!code_postal){
                errors.userCodePostal = <Translation>{(t) => <span>{t("valid_required_3")}</span>}</Translation>;
                isValid = false
            }
        }
        if(step == 3){
            if(!origine_revenu){
                errors.userOrigineRevenu = <Translation>{(t) => <span>{t("valid_required_3")}</span>}</Translation>;
                isValid = false
            }else if(origine_revenu == "EMPLOYEE"){
                if(!nom_employeur){
                    errors.userEmployeur = <Translation>{(t) => <span>{t("valid_required_3")}</span>}</Translation>;
                    isValid = false
                }
                if(telephone_employeur.trim().length < 10){
                    errors.userTelEmployeur =  <Translation>{(t) => <span>{t("valid_required_2")}</span>}</Translation>;
                    isValid = false
                }else if(telephone_employeur == telephone){
                    errors.userTelEmployeur = <Translation>{(t) => <span>{t("valid_required_5")}</span>}</Translation>;
                    isValid = false
                }
                if(!occupation){
                    errors.userOccupation =  <Translation>{(t) => <span>{t("valid_required_3")}</span>}</Translation>;
                    isValid = false
                }
                if(!situation_emploi){
                    errors.userSituation =  <Translation>{(t) => <span>{t("valid_required_3")}</span>}</Translation>;
                    isValid = false
                }
                if(!date_embauche){
                    errors.userDateEmbauche =  <Translation>{(t) => <span>{t("valid_required_3")}</span>}</Translation>;
                    isValid = false
                }else{
                    var dob = date_embauche;
                    var embauche = this.getDate(dob)
                    if(embauche < 0){
                        errors.userDateEmbauche = <Translation>{(t) => <span>{t("valid_required_6")}</span>}</Translation>;
                        isValid = false
                    }
                }
                if(!date_prochaine_paie){
                    errors.userDatePP = <Translation>{(t) => <span>{t("valid_required_3")}</span>}</Translation>;
                    isValid = false
                }else{
                    var dob = date_prochaine_paie;
                    var prochainPaie = this.getDate(dob)
                    if(prochainPaie > 0){
                        errors.userDatePP = <Translation>{(t) => <span>{t("valid_required_7")}</span>}</Translation>;
                        isValid = false
                    }
                }
            }else if(origine_revenu == "Autres"){
                if(!autre_origine_revenu){
                    errors.userOrigineRevenuOther = <Translation>{(t) => <span>{t("valid_required_3")}</span>}</Translation>;
                    isValid = false
                }
            }
            if(nom_reference.trim().length < 2){
                errors.userReference = <Translation>{(t) => <span>{t("valid_required_1")}</span>}</Translation>;
                isValid = false
            }
            if(telephone_reference.trim().length < 10){
                errors.userTelRef = <Translation>{(t) => <span>{t("valid_required_2")}</span>}</Translation>;
                isValid = false
            }else if(telephone_reference == telephone || telephone_reference == telephone_employeur){
                errors.userTelRef =  <Translation>{(t) => <span>{t("valid_required_5")}</span>}</Translation>;
                isValid = false
            }
        }
        if(step == 4){
            if(!revenu_mensuel_brut){
                errors.userRevenuBrut = <Translation>{(t) => <span>{t("valid_required_3")}</span>}</Translation>;
                isValid = false
            }
            if(!loyer_mensuel){
                errors.userLoyer = <Translation>{(t) => <span>{t("valid_required_3")}</span>}</Translation>;
                isValid = false
            }
            if(compte_conjoint == "OUI"){
                if(!nom_compte_conjoint){
                    errors.userNomConjoint = <Translation>{(t) => <span>{t("valid_required_3")}</span>}</Translation>;
                    isValid = false
                }
            }

        }
        //Next step
       this.setState({errors})

        if(isValid ==true && step != 4){
            this.setState({step: step + 1});
        }

        return isValid
    }
    continue = (e) =>{
        e.preventDefault();
        this.nextStep();
    }
    //Call API ans save in DB (marketing)
    saveMarketing = async (e) => {
        e.preventDefault();
        const res = await axios.post('https://gestionkronos.ca/api/save-marketing', this.state)
        if(res.data.status === 200){
            this.setState({error_list: []})
            this.nextStep();
        }else{
            this.setState({
                error_list: res.data.validate_err,
            })
        }
    }
    prevStep = () =>{
        const {step} = this.state;
        this.setState({
            step: step - 1
        });
    }
    retour = e =>{
        e.preventDefault();
        this.prevStep();
    }
    formatPhoneNumber = (value) => {
        if (!value) return value;
        const phoneNumber = value.replace(/[^\d]/g, '');
        const phoneNumberLength = phoneNumber.length;
        if (phoneNumberLength < 4) return phoneNumber;
        if (phoneNumberLength < 7) {
          return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
        }
        return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(
          3,
          6
        )}-${phoneNumber.slice(6, 10)}`;

    }
    handleInput = (e) => {
        this.setState({[e.target.name]: e.target.value});
        this.formatPhoneNumber()
    }
    googlePlace = (place) => {
        this.setState({
            adresse :place.address_components[0].long_name +","+place.address_components[1].long_name,
            ville  :place.address_components[3].long_name,
            code_postal  :place.address_components[8].long_name,
        }) ;
    }

    handleChange = (event) => {
        this.setState({[event.target.name]: event.target.value}, ()=> {
            if(this.state.origine_revenu == "EMPLOYEE"){
                this.setState({isActive: true})
                this.setState({isOther: false})
            }else if(this.state.origine_revenu == "OTHER"){
                this.setState({isOther: true})
                this.setState({isActive: false})
            }else{
                this.setState({isActive: false})
                this.setState({isOther: false})
            }
            if(this.state.compte_conjoint == "OUI"){
                this.setState({isAccount: true})
            }else{
                this.setState({isAccount: false})
            }
        });
    }
    opensweetalert(){
        Swal.fire({
            html:
            '<hr><img src = https://i.postimg.cc/gk60PWGg/gogdfinance.png height="100" alt="logo">' +
            '<br/><hr> <img src = https://i.postimg.cc/T1H2XvtH/percent.gif alt="percent"> ',
            imageHeight: 50,
            title: "<h2 style='color:#479f47'>" +  "Looking for a solution" + "</h2>",
            timer:3000,
            showConfirmButton: false,
            })
        //Swal.showLoading()
    }
    saveLeads = async (e) => {
        const {loyer_mensuel,revenu_mensuel_brut} = this.state;
        e.preventDefault();
        if(!loyer_mensuel || !revenu_mensuel_brut){
            this.nextStep();
        }else{
            this.setState({toSubmit: true})
            //Call API in GK
            const res = await axios.post('https://gestionkronos.ca/api/new-lead', this.state)
            if(res.data.status === 200){
                const user_id = res.data.user_id;
                const id_pret = res.data.id_pret;
                const compagnie = res.data.compagnie;

                if(id_pret == null || id_pret == undefined){
                    var url = compagnie
                }else{
                    url = compagnie+"thank-you/"+id_pret
                }

                //Reload page
                this.opensweetalert();
                //Wait for few secs
                setTimeout(() => {
                    window.location.href =  url
                }, 2500);
            }else{
                this.setState({
                    error_list: res.data.validate_err,
                })
            }

        }
    }

    render() {
        //Set variables
        const {step} = this.state;
        const {email, name, prenom, telephone, errors} = this.state;
        var axios = require('axios');
        var config = {
            method: 'get',
            url: 'https://maps.googleapis.com/maps/api/place/details/json?place_id=ChIJN1t_tDeuEmsRUsoyG83frY4&fields=name%2Crating%2Cformatted_phone_number&key=AIzaSyDxsTkohJeHPpAddZslb3sNzfvp1CEGTa8&libraries=places&callback=initMap',
            headers: { }
          };


        switch (step) {
            case 1:
            return(
                <div>
                    <Header />
                    <section id="contact" className="contact">
                            <div className="container" data-aos="fade-up">
                                <div className="section-title">
                                <h2><Translation>{(t) => <span>{t("form_title")}</span>}</Translation></h2>
                                <p><Translation>{(t) => <span>{t("form_subtitle")}</span>}</Translation></p>
                                </div>
                                <Stepper
                                 steps={[{ label: <Translation>{(t) => <span>{t("step1")}</span>}</Translation> }, { label: <Translation>{(t) => <span>{t("step2")}</span>}</Translation> }, { label: <Translation>{(t) => <span>{t("step3")}</span>}</Translation> }, { label: <Translation>{(t) => <span>{t("step4")}</span>}</Translation> }]}
                                activeStep={0}
                                />
                                <div className="row-12">
                                    <div className="col-lg-12 mt-5 mt-lg-0 d-flex align-items-stretch">
                                        <form className="php-email-form">
                                            <div className="row">
                                            {/* <button onClick={this.opensweetalert}>Open Success Sweetalert Popup</button> */}
                                                {/* Nom */}
                                                {/* <input type='hidden' name="source" value="GGF"/>*/}
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="name"><Translation>{(t) => <span>{t("lastName")}</span>}</Translation></label>
                                                    <input type="text" className={errors.usernameLength ? 'form-control invalid' : 'form-control'} name="name" onChange={this.handleInput} value={this.state.name} placeholder={i18next.t('placeholder_1')} required/>
                                                    <small className='text-danger'>{<div>{errors.usernameLength}</div>}</small>
                                                </div>
                                                {/* Prénom */}
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="prenom"><Translation>{(t) => <span>{t("firstName")}</span>}</Translation></label>
                                                    <input type="text" className={errors.userprenomLength ? 'form-control invalid' : 'form-control'} name="prenom" onChange={this.handleInput} value={this.state.prenom} placeholder={i18next.t('placeholder_2')} required/>
                                                    <small className='text-danger'>{<div>{errors.userprenomLength}</div>}</small>
                                                </div>
                                                {/* Courriel */}
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="name"><Translation>{(t) => <span>{t("email")}</span>}</Translation></label>
                                                    <input type="text" className={this.state.error_list && this.state.error_list.email ? 'form-control invalid' : 'form-control'} onChange={this.handleInput} value={this.state.email} name="email" placeholder={i18next.t('placeholder_3')}/>
                                                    <small className='text-danger'>{this.state.error_list && this.state.error_list.email ? this.state.error_list.email : ''}</small>
                                                </div>
                                                {/* Téléphone */}
                                                <div className="form-group col-md-6">
                                                     <label htmlFor="name"><Translation>{(t) => <span>{t("phone")}</span>}</Translation></label>
                                                    <PhoneInput
                                                    inputClass="form-group col-md-6"
                                                    className={errors.userphone ? 'invalid' : 'none'}
                                                    country={'ca'}
                                                    value={this.state.telephone}
                                                    name="telephone"
                                                    onlyCountries={['ca']}
                                                    onChange={telephone => this.setState({ telephone })}
                                                    inputStyle={{
                                                        width: "100%"
                                                    }}
                                                    disableCountryCode = {true}
                                                    placeholder = {'(514) 333 3333'}
                                                    />
                                                    <small className='text-danger'>{<div>{errors.userphone}</div>}</small>
                                                </div>
                                                 {/* Mot de passe */}
                                                 <div className="form-group col-md-6">
                                                    <label htmlFor="password"><Translation>{(t) => <span>{t("password")}</span>}</Translation></label>
                                                    <input type="password" className={errors.userpasswordLength ? 'form-control invalid' : 'form-control'} name="password" onChange={this.handleInput} value={this.state.password} placeholder={i18next.t('placeholder_20')} required/>
                                                    <small className='text-danger'>{<div>{errors.userpasswordLength}</div>}</small>
                                                </div>
                                                 {/* Confirmation de Mot de passe */}
                                                 <div className="form-group col-md-6">
                                                    <label htmlFor="password"><Translation>{(t) => <span>{t("passwordConfirm")}</span>}</Translation></label>
                                                    <input type="password" className={errors.userpasswordConfirmLength ? 'form-control invalid' : 'form-control'} name="passwordConfirm" onChange={this.handleInput} value={this.state.passwordConfirm} placeholder={i18next.t('placeholder_21')} required/>
                                                    <small className='text-danger'>{<div>{errors.userpasswordConfirmLength}</div>}</small>
                                                </div>

                                            </div>
                                            <div className="text-center">
                                                <button onClick={this.saveMarketing} className='btn btn-dark next'><Translation>{(t) => <span>{t("button_continue")}</span>}</Translation> </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>

                </div>
            );
            case 2:
                return(
                    <div>
                        <Seo
                            title="Demande de prêt rapide en ligne | Good Good Finance"
                            description="Découvrez une demande de prêt rapide et transparente en ligne sur Good Good Finance. Obtenez les fonds dont vous avez besoin en toute simplicité et commodité. Postulez dès aujourd'hui !"
                            type="webapp"
                            name="APPLICATION FORM"
                            url_content="https://argentaujourdhui.com/new-lead"
                            url_external_content="https://goodgoodfinance.com/new-lead"
                            />
                        <Header/>
                        <section id="contact" className="contact">
                            <div className="container" data-aos="fade-up">
                                <div className="section-title">
                                    <h2><Translation>{(t) => <span>{t("form_title")}</span>}</Translation></h2>
                                </div>
                                <Stepper
                                    steps={[{
                                        label: <Translation>{(t) => <span>{t("step1")}</span>}</Translation>
                                    }, {label: <Translation>{(t) => <span>{t("step2")}</span>}</Translation>}, {
                                        label: <Translation>{(t) => <span>{t("step3")}</span>}</Translation>
                                    }, {label: <Translation>{(t) => <span>{t("step4")}</span>}</Translation>}]}
                                    activeStep={1}
                                />
                                <div className="row-12">
                                    <div className="col-lg-12 mt-5 mt-lg-0 d-flex align-items-stretch">
                                        <form className="php-email-form">
                                            <div className="row">
                                                {/* Montant */}
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="name"><Translation>{(t) =>
                                                        <span>{t("amount")}</span>}</Translation></label>
                                                    <select
                                                        className={errors.userMontant ? 'form-select invalid' : 'form-select'}
                                                        aria-label="Default select example" onChange={this.handleChange}
                                                        value={this.state.montant} name='montant'>
                                                        <option value="" disabled selected><Translation>{(t) =>
                                                            <span>{t("amount_text")}</span>}</Translation></option>
                                                        <option value="100">100$</option>
                                                        <option value="250">250$</option>
                                                        <option value="500">500$</option>
                                                        <option value="750">750$</option>
                                                        <option value="1000">1000$</option>
                                                        <option value="1250">1250$</option>
                                                        <option value="1500">1500$</option>
                                                        <option value="1750">1750$</option>
                                                        <option value="2000">2000$</option>
                                                    </select>
                                                    <small className='text-danger'>{
                                                        <div>{errors.userMontant}</div>}</small>
                                                </div>

                                                {/* Fréquence de rebouressement */}
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="name"><Translation>{(t) =>
                                                        <span>{t("frequence")}</span>}</Translation></label>
                                                    <select
                                                        className={errors.userFrequence ? 'form-select invalid' : 'form-select'}
                                                        aria-label="Default select example" onChange={this.handleChange}
                                                        value={this.state.frequence_remboursement}
                                                        name='frequence_remboursement'>
                                                        <option value="" disabled selected><Translation>{(t) =>
                                                            <span>{t("frequence_text")}</span>}</Translation></option>
                                                        <option value="Chaque semaine"><Translation>{(t) =>
                                                            <span>{t("weekly")}</span>}</Translation></option>
                                                        <option value="Aux 2 semaines"><Translation>{(t) =>
                                                            <span>{t("bi-weekly")}</span>}</Translation></option>
                                                        <option value="Bimensuel"><Translation>{(t) =>
                                                            <span>{t("bimensuel")}</span>}</Translation></option>
                                                    </select>
                                                    <small className='text-danger'>{
                                                        <div>{errors.userFrequence}</div>}</small>
                                                </div>
                                                {/* Adresse */}
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="name"><Translation>{(t) =>
                                                        <span>{t("adress")}</span>}</Translation></label>
                                                    {/* <input type="text" className="form-control" name="adresse" onChange={this.handleInput} value={this.state.adresse} placeholder={i18next.t('placeholder_4')}/> */}
                                                    <Autocomplete
                                                        className={errors.userAdresse ? 'form-control invalid' : 'form-control'}
                                                        name="adresse"
                                                        value={this.state.adresse}
                                                        apiKey={'AIzaSyDxsTkohJeHPpAddZslb3sNzfvp1CEGTa8&libraries=places&callback=initMap'}
                                                        onPlaceSelected={(place) => {
                                                            this.googlePlace(place)
                                                        }}
                                                        onChange={this.handleInput}
                                                        placeholder={i18next.t('placeholder_4')}
                                                        options={{
                                                            types: ['address'],
                                                            componentRestrictions: {country: "ca"},
                                                        }}
                                                    />
                                                    <small className='text-danger'>{
                                                        <div>{errors.userAdresse}</div>}</small>
                                                </div>
                                                {/* Appartement */}
                                                <div className="form-group col-md-2">
                                                    <label htmlFor="name"><Translation>{(t) =>
                                                        <span>{t("app")}</span>}</Translation></label>
                                                    <input type="text" className="form-control" name="appartement"
                                                           onChange={this.handleInput} value={this.state.appartement}
                                                           placeholder='#APP'/>
                                                    <small className='text-danger'>{
                                                        <div>{errors.userAppartement}</div>}</small>
                                                </div>
                                                {/* Date de naissance */}
                                                {/* <div className="form-group col-md-4">
                                                    <label htmlFor="name"><Translation>{(t) => <span>{t("birthDay")}</span>}</Translation></label>
                                                    <input type="date" className="form-control" onChange={this.handleInput} value={this.state.date_naissance} name="date_naissance" max="2007-12-31" min="1940-12-31"/>
                                                    <small className='text-danger'>{<div>{errors.userDateNaissance}</div>}</small>
                                                </div> */}
                                                <div className="form-group col-md-4">
                                                    <label htmlFor="name"><Translation>{(t) =>
                                                        <span>{t("birthDay")}</span>}</Translation></label> <br/>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                            className='custom-input form-control'
                                                            openTo="year"
                                                            views={['year', 'month', 'day']}
                                                            value={this.state.date_naissance}
                                                            onChange={date_naissance => this.setState({date_naissance})}
                                                            name="date_naissance"
                                                            placeholder="{i18next.t('birthDay')}"
                                                            maxDate='2006-01-01'
                                                            renderInput={(params) => <TextField {...params}
                                                                                                className={errors.userDateNaissance ? 'col-12 custom-input invalid' : 'col-12 custom-input'}
                                                            />}
                                                        />
                                                    </LocalizationProvider>
                                                    <small className='text-danger'>{
                                                        <div>{errors.userDateNaissance}</div>}</small>
                                                </div>
                                                {/* Ville */}
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="name"><Translation>{(t) =>
                                                        <span>{t("city")}</span>}</Translation></label>
                                                    <input type="text"
                                                           className={errors.userVille ? 'form-control invalid' : 'form-control'}
                                                           name="ville" onChange={this.handleInput}
                                                           value={this.state.ville}
                                                           placeholder={i18next.t('placeholder_5')}/>
                                                    <small className='text-danger'>{
                                                        <div>{errors.userVille}</div>}</small>
                                                </div>
                                                {/* Code postale */}
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="name"><Translation>{(t) =>
                                                        <span>{t("zip")}</span>}</Translation></label>
                                                    <input type="text"
                                                           className={errors.userCodePostal ? 'form-control invalid' : 'form-control'}
                                                           name="code_postal" onChange={this.handleInput}
                                                           value={this.state.code_postal}
                                                           placeholder={i18next.t('placeholder_6')}/>
                                                    <small className='text-danger'>{
                                                        <div>{errors.userCodePostal}</div>}</small>
                                                </div>


                                                {/* Province */}
                                                <div className="form-group col-md-3">
                                                    <label htmlFor="name"><Translation>{(t) =>
                                                        <span>{t("province")}</span>}</Translation></label>
                                                    <select className='form-select' aria-label="Default select example"
                                                            onChange={this.handleInput} value={this.state.province}
                                                            name="province">
                                                        <option value="">Province</option>
                                                        <option value="AB">Alberta</option>
                                                        <option value="BC">British Columbia</option>
                                                        <option value="MB">Manitoba</option>
                                                        <option value="NB">New Brunswick</option>
                                                        <option value="NL">Newfoundlwand and Labrador</option>
                                                        <option value="NT">Northwest Territories</option>
                                                        <option value="NS">Nova Scotia</option>
                                                        <option value="NU">Nunavut</option>
                                                        <option value="ON">Ontario</option>
                                                        <option value="PE">Prince Edward Island</option>
                                                        <option value="QC">Québec</option>
                                                        <option value="SK">Saskatechewan</option>
                                                        <option value="YU">Yukon</option>
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <button onClick={this.retour} className='btn btn-secondary previous'>
                                                    <Translation>{(t) =>
                                                        <span>{t("button_previous")}</span>}</Translation></button>
                                                <button onClick={this.continue} className='btn btn-dark next'>
                                                    <Translation>{(t) =>
                                                        <span>{t("button_continue")}</span>}</Translation></button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                );
            case 3:
                return (
                    <div>
                        <Seo
                            title="Demande de prêt rapide en ligne | Good Good Finance"
                                description="Découvrez une demande de prêt rapide et transparente en ligne sur Good Good Finance. Obtenez les fonds dont vous avez besoin en toute simplicité et commodité. Postulez dès aujourd'hui !"
                                type="webapp"
                                name="APPLICATION FORM"
                                url_content="https://argentaujourdhui.com/new-lead"
                                 url_external_content="https://goodgoodfinance.com/new-lead"
                            />
                            <Header />
                            <section id="contact" className="contact">
                            <div className="container" data-aos="fade-up">
                                <div className="section-title">
                                <h2><Translation>{(t) => <span>{t("form_title")}</span>}</Translation></h2>
                                </div>
                                <Stepper
                                     steps={[{ label: <Translation>{(t) => <span>{t("step1")}</span>}</Translation> }, { label: <Translation>{(t) => <span>{t("step2")}</span>}</Translation> }, { label: <Translation>{(t) => <span>{t("step3")}</span>}</Translation> }, { label: <Translation>{(t) => <span>{t("step4")}</span>}</Translation> }]}
                                    activeStep={2}
                                    />
                                   <div className="row-12">
                                        <div className="col-lg-12 mt-5 mt-lg-0 d-flex align-items-stretch">
                                            <form className="php-email-form">
                                                <div className="row">
                                               {/* Origine de revenu */}
                                               <div className="form-group col-md-12">
                                                    <label htmlFor="name"><Translation>{(t) => <span>{t("origineRevenu")}</span>}</Translation> </label>
                                                    <select className={errors.userOrigineRevenu ? 'form-select invalid' : 'form-select'} aria-label="Default select example" onChange={this.handleChange} value={this.state.origine_revenu} name='origine_revenu'>
                                                        <option value="" disabled selected><Translation>{(t) => <span>{t("origine_revenu_text")}</span>}</Translation></option>
                                                        <option value="EMPLOYEE"><Translation>{(t) => <span>{t("salarie")}</span>}</Translation></option>
                                                        <option value="UNEMPLOYED"><Translation>{(t) => <span>{t("chaomage")}</span>}</Translation></option>
                                                        <option value="RETIRED"><Translation>{(t) => <span>{t("retraite")}</span>}</Translation></option>
                                                        <option value="RQAP"><Translation>{(t) => <span>{t("rqap")}</span>}</Translation></option>
                                                        <option value="SAAQ"><Translation>{(t) => <span>{t("saaq")}</span>}</Translation></option>
                                                        <option value="SOCIAL_BENEFITS"><Translation>{(t) => <span>{t("aide_social")}</span>}</Translation></option>
                                                        <option value="OTHER"><Translation>{(t) => <span>{t("other")}</span>}</Translation></option>
                                                    </select>
                                                    <small className='text-danger'>{<div>{errors.userOrigineRevenu}</div>}</small>
                                                </div>
                                                 {/* Autre origine revenu */}
                                                 {this.state.isOther ? <div className="form-group col-md-12">
                                                    <label htmlFor="name"><Translation>{(t) => <span>{t("origineRevenu")}</span>}</Translation> </label>
                                                    <input type="text" className={errors.userOrigineRevenuOther ? 'form-control invalid' : 'form-control'} onChange={this.handleInput} value={this.state.autre_origine_revenu} name="autre_origine_revenu" placeholder={i18next.t('placeholder_7')}/>
                                                    <small className='text-danger'>{<div>{errors.userOrigineRevenuOther}</div>}</small>
                                                </div> : null }
                                               {/* Nom de l'employeur */}
                                                {this.state.isActive ? <div className="form-group col-md-6">
                                                    <label htmlFor="name"><Translation>{(t) => <span>{t("employer")}</span>}</Translation> </label>
                                                    <input type="text" className={errors.userEmployeur ? 'form-control invalid' : 'form-control'} onChange={this.handleInput} value={this.state.nom_employeur} name="nom_employeur" placeholder={i18next.t('placeholder_8')}/>
                                                    <small className='text-danger'>{<div>{errors.userEmployeur}</div>}</small>
                                                </div> : null }
                                                {/* Téléphone de l'employeur */}
                                                {this.state.isActive ?<div className="form-group col-md-6">
                                                    <label htmlFor="name"><Translation>{(t) => <span>{t("phone_employer")}</span>}</Translation> </label>
                                                    <PhoneInput
                                                    inputClass="form-group col-md-6"
                                                    className={errors.userTelEmployeur ? 'invalid' : 'none'}
                                                    country={'ca'}
                                                    value={this.state.telephone_employeur}
                                                    name="telephone_employeur"
                                                    onlyCountries={['ca']}
                                                    onChange={telephone_employeur => this.setState({ telephone_employeur })}
                                                    inputStyle={{
                                                        width: "100%"
                                                    }}
                                                    disableCountryCode = {true}
                                                    placeholder = {'(514) 123 1234'}
                                                    />
                                                    <small className='text-danger'>{<div>{errors.userTelEmployeur}</div>}</small>
                                                </div>: null }
                                                {/* Poste */}
                                                {this.state.isActive ?<div className="form-group col-md-6">
                                                    <label htmlFor="name"><Translation>{(t) => <span>{t("occupation")}</span>}</Translation> </label>
                                                    <input type="text" className={errors.userOccupation ? 'form-control invalid' : 'form-control'} onChange={this.handleInput} value={this.state.occupation} name="occupation" placeholder={i18next.t('placeholder_9')}/>
                                                    <small className='text-danger'>{<div>{errors.userOccupation}</div>}</small>
                                                </div>: null }
                                                {/* Situation d'emploi*/}
                                                {this.state.isActive ?<div className="form-group col-md-6">
                                                    <label htmlFor="name"><Translation>{(t) => <span>{t("situation")}</span>}</Translation> </label>
                                                    <select className={errors.userSituation ? 'form-select invalid' : 'form-select'} aria-label="Default select example" onChange={this.handleChange} value={this.state.situation_emploi} name='situation_emploi'>
                                                        <option value="" disabled selected><Translation>{(t) => <span>{t("situation_text")}</span>}</Translation></option>
                                                        <option value="FULL_TIME"><Translation>{(t) => <span>{t("plein")}</span>}</Translation></option>
                                                        <option value="PART_TIME"><Translation>{(t) => <span>{t("partiel")}</span>}</Translation></option>
                                                        <option value="TEMPORARY"><Translation>{(t) => <span>{t("temporaire")}</span>}</Translation></option>
                                                        <option value="OTHER"><Translation>{(t) => <span>{t("other")}</span>}</Translation></option>
                                                    </select>
                                                    <small className='text-danger'>{<div>{errors.userSituation}</div>}</small>
                                                </div>: null }
                                                {/* Date d'embauche */}
                                                {this.state.isActive ?<div className="form-group col-md-6">
                                                    {/* <label htmlFor="name"><Translation>{(t) => <span>{t("date_embauche")}</span>}</Translation> </label>
                                                    <input type="date" className="form-control" onChange={this.handleInput} value={this.state.date_embauche} name="date_embauche" min="2000-01-01" max="2024-01-01"/>
                                                    <small className='text-danger'>{<div>{errors.userDateEmbauche}</div>}</small>  */}
                                                    <label htmlFor="name"><Translation>{(t) => <span>{t("date_embauche")}</span>}</Translation></label> <br/>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                        className='custom-input form-control'
                                                        openTo="year"
                                                        views={['year', 'month', 'day']}
                                                        value={this.state.date_embauche}
                                                        onChange={date_embauche => this.setState({ date_embauche })}
                                                        name="date_embauche"
                                                        renderInput={(params) => <TextField {...params}
                                                        className={errors.userDateEmbauche ? 'col-12 custom-input invalid' : 'col-12 custom-input'}/>}
                                                        />
                                                    </LocalizationProvider>
                                                    <small className='text-danger'>{<div>{errors.userDateEmbauche}</div>}</small>
                                                </div>: null }

                                                {/* Date de la prochaine paie */}
                                                {/* {this.state.isActive ?<div className="form-group col-md-6">
                                                    <label htmlFor="name"><Translation>{(t) => <span>{t("date_pay")}</span>}</Translation> </label>
                                                    <input type="date" className="form-control" onChange={this.handleInput} value={this.state.date_prochaine_paie} name="date_prochaine_paie" min="2023-01-01" max="2024-01-01"/>
                                                    <small className='text-danger'>{<div>{errors.userDatePP}</div>}</small>
                                                </div>: null } */}
                                                {this.state.isActive ?<div className="form-group col-md-6">
                                                    <label htmlFor="name"><Translation>{(t) => <span>{t("date_pay")}</span>}</Translation></label> <br/>
                                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                        <DatePicker
                                                        className='custom-input form-control'
                                                        openTo="year"
                                                        views={['year', 'month', 'day']}
                                                        value={this.state.date_prochaine_paie}
                                                        onChange={date_prochaine_paie => this.setState({ date_prochaine_paie })}
                                                        name="date_prochaine_paie"
                                                        renderInput={(params) => <TextField {...params}
                                                        className={errors.userDatePP ? 'col-12 custom-input invalid' : 'col-12 custom-input'}/>}
                                                        />
                                                    </LocalizationProvider>
                                                    <small className='text-danger'>{<div>{errors.userDatePP}</div>}</small>
                                                </div>: null }
                                                {/* Nom de la référence */}
                                                <div className="form-group col-md-12">
                                                    <label htmlFor="name"><Translation>{(t) => <span>{t("reference")}</span>}</Translation> </label>
                                                    <input type="text" className={errors.userReference ? 'form-control invalid' : 'form-control'} onChange={this.handleInput} value={this.state.nom_reference} name="nom_reference" placeholder={i18next.t('placeholder_10')}/>
                                                    <small className='text-danger'>{<div>{errors.userReference}</div>}</small>
                                                </div>
                                                {/* Téléphone de la référence */}
                                                <div className="form-group col-md-6">
                                                     <label htmlFor="name"><Translation>{(t) => <span>{t("phone_ref")}</span>}</Translation> </label>
                                                    <PhoneInput
                                                    inputClass="form-group col-md-6"
                                                    className={errors.userTelRef ? 'invalid' : 'none'}
                                                    country={'ca'}
                                                    value={this.state.telephone_reference}
                                                    name="telephone_reference"
                                                    onlyCountries={['ca']}
                                                    onChange={telephone_reference => this.setState({ telephone_reference })}
                                                    inputStyle={{
                                                        width: "100%"
                                                    }}
                                                    disableCountryCode = {true}
                                                    placeholder = {'(514) 333 3333'}
                                                    />
                                                    <small className='text-danger'>{<div>{errors.userTelRef}</div>}</small>
                                                </div>
                                                {/* Courriel de la référence */}
                                                <div className="form-group col-md-6">
                                                    <label htmlFor="name"><Translation>{(t) => <span>{t("email_ref")}</span>}</Translation> </label>
                                                    <input type="text" className="form-control" onChange={this.handleInput} value={this.state.email_reference} name="email_reference" placeholder={i18next.t('placeholder_11')}/>
                                                </div>
                                                </div>
                                                 <div className="text-center">
                                                    <button onClick={this.retour} className='btn btn-secondary previous'><Translation>{(t) => <span>{t("button_previous")}</span>}</Translation></button>
                                                    <button onClick={this.continue} className='btn btn-dark next'><Translation>{(t) => <span>{t("button_continue")}</span>}</Translation></button>
                                                </div>
                                                </form>
                                        </div>
                                    </div>
                                </div>
                        </section>
                        </div>
                    );
            case 4:
                    return(
                        <div>
                            <Seo
                                title="Demande de prêt rapide en ligne | Good Good Finance"
                                description="Découvrez une demande de prêt rapide et transparente en ligne sur Good Good Finance. Obtenez les fonds dont vous avez besoin en toute simplicité et commodité. Postulez dès aujourd'hui !"
                                type="webapp"
                                name="APPLICATION FORM"
                                url_content="https://argentaujourdhui.com/new-lead"
                                url_external_content="https://goodgoodfinance.com/new-lead"
                            />
                            <Header />
                            <section id="contact" className="contact">
                            <div className="container" data-aos="fade-up">
                                <div className="section-title">
                                <h2><Translation>{(t) => <span>{t("form_title")}</span>}</Translation></h2>
                                </div>
                                <Stepper
                                    steps={[{ label: <Translation>{(t) => <span>{t("step1")}</span>}</Translation> }, { label: <Translation>{(t) => <span>{t("step2")}</span>}</Translation> }, { label: <Translation>{(t) => <span>{t("step3")}</span>}</Translation> }, { label: <Translation>{(t) => <span>{t("step4")}</span>}</Translation> }]}
                                    activeStep={3}
                                    />
                                   <div className="row-12">
                                        <div className="col-lg-12 mt-5 mt-lg-0 d-flex align-items-stretch">
                                            <form className="php-email-form">
                                                <div className="row">
                                                    {/* Faillite */}
                                                    <div className="form-group col-md-10">
                                                        <label htmlFor="name"><Translation>{(t) =>
                                                            <span>{t("faillite")}</span>}</Translation></label>
                                                    </div>
                                                    <div className="form-group col-md-2">
                                                        <select className='form-select'
                                                                aria-label="Default select example"
                                                                onChange={this.handleInput} value={this.state.faillite}
                                                                name='faillite'>
                                                            <option value="TRUE"><Translation>{(t) =>
                                                                <span>{t("no")}</span>}</Translation></option>
                                                            <option value="FALSE"><Translation>{(t) =>
                                                                <span>{t("yes")}</span>}</Translation></option>
                                                        </select>
                                                    </div>

                                                    {/* Revenu mensuel brut */}
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="name"><Translation>{(t) =>
                                                            <span>{t("revenut_brut")}</span>}</Translation></label>
                                                        <input type="number"
                                                               className={errors.userRevenuBrut ? 'form-control invalid' : 'form-control'}
                                                               onChange={this.handleInput}
                                                               value={this.state.revenu_mensuel_brut}
                                                               name="revenu_mensuel_brut"
                                                               placeholder={i18next.t('placeholder_12')} required/>
                                                        <small className='text-danger'>{
                                                            <div>{errors.userRevenuBrut}</div>}</small>
                                                    </div>
                                                    {/* Loyer mensuel */}
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="name"><Translation>{(t) =>
                                                            <span>{t("loyer")}</span>}</Translation></label>
                                                        <input type="number"
                                                               className={errors.userLoyer ? 'form-control invalid' : 'form-control'}
                                                               onChange={this.handleInput}
                                                               value={this.state.loyer_mensuel} name="loyer_mensuel"
                                                               placeholder={i18next.t('placeholder_13')} required/>
                                                        <small className='text-danger'>{
                                                            <div>{errors.userLoyer}</div>}</small>
                                                    </div>
                                                    {/* Montant d'électricité mensuel */}
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="name"><Translation>{(t) =>
                                                            <span>{t("electricite")}</span>}</Translation></label>
                                                        <input type="number" className="form-control"
                                                               onChange={this.handleInput}
                                                               value={this.state.montant_electricite_mensuel}
                                                               name="montant_electricite_mensuel"
                                                               placeholder={i18next.t('placeholder_14')}/>
                                                    </div>
                                                    {/* Autres déboursés mensuels liés à l'habitation */}
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="name"><Translation>{(t) =>
                                                            <span>{t("habitation")}</span>}</Translation></label>
                                                        <input type="number" className="form-control"
                                                               onChange={this.handleInput}
                                                               value={this.state.autre_montant_mensuel_habit}
                                                               name="autre_montant_mensuel_habit"
                                                               placeholder={i18next.t('placeholder_15')}/>
                                                    </div>
                                                    {/* Location auto */}
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="name"><Translation>{(t) =>
                                                            <span>{t("auto")}</span>}</Translation></label>
                                                        <input type="number" className="form-control"
                                                               onChange={this.handleInput}
                                                               value={this.state.montant_loc_auto}
                                                               name="montant_loc_auto"
                                                               placeholder={i18next.t('placeholder_16')}/>
                                                    </div>
                                                    {/* Paiements achat de meubles */}
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="name"><Translation>{(t) =>
                                                            <span>{t("meubles")}</span>}</Translation></label>
                                                        <input type="number" className="form-control"
                                                               onChange={this.handleInput}
                                                               value={this.state.montant_achat_meuble}
                                                               name="montant_achat_meuble"
                                                               placeholder={i18next.t('placeholder_17')}/>
                                                    </div>
                                                    {/* Autres obligations */}
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="name"><Translation>{(t) =>
                                                            <span>{t("obligations")}</span>}</Translation></label>
                                                        <input type="number" className="form-control"
                                                               onChange={this.handleInput}
                                                               value={this.state.montant_autre_oblig}
                                                               name="montant_autre_oblig"
                                                               placeholder={i18next.t('placeholder_18')}/>
                                                    </div>
                                                    {/* Compte conjoint */}
                                                    <div className="form-group col-md-6">
                                                        <label htmlFor="name"><Translation>{(t) =>
                                                            <span>{t("conjoint")}</span>}</Translation></label>
                                                        <select className='form-select'
                                                                aria-label="Default select example"
                                                                onChange={this.handleChange} name='compte_conjoint'>
                                                            <option value="NON"><Translation>{(t) =>
                                                                <span>{t("no")}</span>}</Translation></option>
                                                            <option value="OUI"><Translation>{(t) =>
                                                                <span>{t("yes")}</span>}</Translation></option>
                                                        </select>
                                                    </div>
                                                    {/* Nom conjoint(e) */}
                                                    {this.state.isAccount ? <div className="form-group col-md-12">
                                                        <label htmlFor="name"><Translation>{(t) =>
                                                            <span>{t("name_conjoint")}</span>}</Translation></label>
                                                        <input type="text"
                                                               className={errors.userNomConjoint ? 'form-control invalid' : 'form-control'}
                                                               onChange={this.handleInput}
                                                               value={this.state.nom_compte_conjoint}
                                                               name="nom_compte_conjoint"
                                                               placeholder={i18next.t('placeholder_19')}/>
                                                        <small className='text-danger'>{
                                                            <div>{errors.userNomConjoint}</div>}</small>
                                                    </div> : null}
                                                    <div className="form-group col-md-10">
                                                        <label htmlFor="name"><Translation>{(t) =>
                                                            <span>{t("faillite")}</span>}</Translation></label>
                                                    </div>
                                                    <div className="form-group col-md-2">
                                                        <select className='form-select'
                                                                aria-label="Default select example"
                                                                onChange={this.handleInput} value={this.state.endorser}
                                                                name='endorser'>
                                                            <option value="0"><Translation>{(t) =>
                                                                <span>{t("no")}</span>}</Translation></option>
                                                            <option value="1"><Translation>{(t) =>
                                                                <span>{t("yes")}</span>}</Translation></option>
                                                        </select>
                                                    </div>

                                                    <div className="text-center">
                                                        <button onClick={this.retour}
                                                                className='btn btn-secondary previous'>
                                                            <Translation>{(t) =>
                                                                <span>{t("button_previous")}</span>}</Translation>
                                                        </button>
                                                        <button onClick={this.saveLeads} disabled={this.state.toSubmit}
                                                                className='btn btn-dark next'><Translation>{(t) =>
                                                            <span>{t("send_form")}</span>}</Translation> <Spinner
                                                            animation={this.state.toSubmit ? 'border' : null}
                                                            variant="warning" size="sm"/></button>
                                                    </div>

                                                </div>
                                            </form>
                                        </div>
                                   </div>
                            </div>
                            </section>
                        </div>
                    );
        }

    }
}

export default NewLead;

