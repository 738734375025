import React, { Component } from 'react';
import { Helmet } from 'react-helmet-async';

import Header from './Navigattion/Header';
import Footer from './Navigattion/Footer';
import Fonctionnement from './Components/Fonctionnement';
import Faq from './Components/Faq';
import Slider from './Components/Slider';
import LogoCompagnie from './Components/LogoCompagnie';
import Notice from './Components/Notice';
import Notice1 from './Components/Notice1';
import Notice2 from './Components/Notice2';
import Section1 from './Components/Section1';
import Section2 from './Components/Section2';
import { Seo } from '../helpers/Seo';

class Home extends Component {
  render() {
    return (
        <div>
            <Seo
                title="$100 Approval Personal Loans in Canada | No Credit Check | Good Good Finance"
                description="Découvrez des prêts personnels rapides et faciles avec une approbation de 100 $ chez Good Good Finance. Obtenez le soutien financier dont vous avez besoin aujourd'hui grâce à notre application simple."
                type="webapp"
                name="Good Good Finance"
            />
            <div id='oopss'>
                <div id='error-text'>
                    <img src="https://cdn.rawgit.com/ahmedhosna95/upload/1731955f/sad404.svg" alt="404"/>
                    <span className="title_404">404 PAGE</span>
                    <p className="p-a">
                        Page not found</p>

                    <a href="https://goodgoodfinance.com" className="link">
                        Retour à l'acceuil  &#8618;</a>
                </div>
            </div>
        </div>
    );
  }
}

export default Home;

