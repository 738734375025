
import React, {Component} from 'react';
import 'react-multi-carousel/lib/styles.css';


class LogoCompagnie extends Component {
  
    render() {
      
        return (
    <div>
      
        
      <section id="clients" className="clients section-bg">
        <div className="container">
          <div className="row" data-aos="zoom-in">
            <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="../assets/img/logos/xtra.webp" className="img-fluid" alt="PRETXTRA.CA" width={'100%'} />
            </div>
            <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="../assets/img/logos/finexa_logo.png" className="img-fluid" alt="FINEXA" width={'70%'} />
            </div>
            <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="../assets/img/logos/hebdo.svg" className="img-fluid" alt="HEBDO" width={'70%'} />
            </div>
            <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="../assets/img/logos/alt.webp" className="img-fluid" alt="PRET ALTERNATIF" width={'100%'} />
            </div>
            <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="../assets/img/logos/tpp.webp" className="img-fluid" alt="TOP PRET PERSO" width={'100%'} />
            </div>
            <div className="col-lg-2 col-md-4 col-6 d-flex align-items-center justify-content-center">
              <img src="../assets/img/logos/kp.svg" className="img-fluid" alt="KreditPret" width={'100%'} />
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}
}
export default  LogoCompagnie;