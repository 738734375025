import React, { useTransition } from 'react';
import {BrowserRouter as Router, Switch, Route, Redirect} from 'react-router-dom';
import Home from './pages/Home'
import NewLead from './pages/NewLead'
import NotFound from './pages/NotFound'
import Politique from './pages/Politique'
import { HelmetProvider } from 'react-helmet-async';
import i18n from "i18next";



function App() {
    const helmetContext = {};
    const changeLanguage = (lang) => {
        i18n.changeLanguage(lang);
    };

    const handleRedirect = () => {
        window.location.href = 'https://goodgoodfinance.com';
    };

    return (
        <HelmetProvider context={helmetContext}>
            <Router>

                <Switch>
                    <Route
                        exact
                        path="/"
                        render={() => {
                            changeLanguage('en');
                            return <Home />;
                        }}
                    />
                    <Route
                        path="/fr"
                        render={() => {
                            window.location.href = 'https://argentaujourdhui.com';
                            return null;
                        }}
                    />

                    <Route
                        path="/en"
                        render={() => {
                            handleRedirect();
                            return null;
                        }}
                    />

                    <Route exact path="/new-lead" component={NewLead} />
                    <Route path="/*" component={NotFound} />
                    <Route exact path="/politique_confidentialite" component={Politique} />
                </Switch>

            </Router>
        </HelmetProvider>
    );
}

export default App;
